import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Stack,
  Typography,
  Modal,
  Fade,
} from '@mui/material';
import ChooseYourAge from '../Components/ChooseYourAge';
import FoodPreferences from '../Components/FoodPreferences';
import MedicalCondition from '../Components/MedicalCondition';
import Allergies from '../Components/Allergies';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/system';

const StyledButton = styled(Button)((props) => ({
  padding: '0.625rem 1.25rem',
  marginTop: '0.625rem',
  backgroundColor: '#17527D',
  color: '#FFFFFF',
  border: 'none',
  textTransform: 'capitalize',
  borderRadius: '0.30rem',
  height: '3.1875rem',

  '& a': {
    color: 'inherit',
    textDecoration: 'none',
  },

  '&:hover': {
    backgroundColor: '#F5F5F5',
    color: '#17527D',
  },
}));

const EditModal = ({ open, onClose }) => {
  const [value, setValue] = useState('');
  const [medicalCondition, setMedicalCondition] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [age, setAge] = useState('');
  const [foodPreferences, setFoodPreferences] = useState([]);
  console.log({ value });

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSave = () => {
    const userData = {
      gender: value,
      diagnoses: medicalCondition,
      allergies: allergies,
      age: age,
      preferences: foodPreferences,
    };
    localStorage.setItem('userData', JSON.stringify(userData));
    onClose();
  };
  

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open}>
        <Box sx={{ backgroundColor: 'white', p: 3 }}>
          <Typography
            variant="h4"
            color="primary"
            align="center"
            marginBottom={1}
          >
            Edit Your Data
          </Typography>
          <Typography
            variant="subtitle2"
            color="primary"
            align="center"
            sx={{ fontWeight: 600 }}
            marginBottom={3}
          >
            Personalized guide to optimal well-being, exploring the vitamins and
            minerals in food tailored to your unique needs
          </Typography>
          <Box
            className="typeYourMedicalCondition"
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            marginBottom={4}
          >
            <MedicalCondition onChange={(value) => setMedicalCondition(value)} />
          </Box>
          <Box
            className="typeYourAlergies"
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            marginBottom={4}
          >
            <Allergies onChange={(value) => setAllergies(value)} />
          </Box>
          <Box
            className="chooseYourAge"
            sx={{ width: '100%' }}
            marginBottom={4}
          >
            <ChooseYourAge onChange={(value) => setAge(value)} />
          </Box>
          <Box
            className="foodPreferences"
            sx={{ width: '100%' }}
            marginBottom={4}
          >
            <FoodPreferences onChange={(value) => setFoodPreferences(value)} />
          </Box>
          <Box
            className="gender"
            sx={{ width: '100%', marginLeft: 'auto' }}
            marginBottom={4}
          >
            <FormControl primary="true">
              <FormLabel id="select-gender-group-label">
                <Typography variant="main" color="primary">
                  Select your gender
                </Typography>
              </FormLabel>
              <RadioGroup
                name="select-gender"
                aria-labelledby="select-gender-group-label"
                value={value}
                onChange={handleChange}
                row
                color="primary"
              >
                <FormControlLabel
                  control={<Radio size="small" />}
                  label="Male"
                  value="Male"
                  color="primary"
                  style={{ color: '#17527D' }}
                />
                <FormControlLabel
                  control={<Radio size="small" />}
                  label="Female"
                  value="Female"
                  color="primary"
                  style={{ color: '#17527D' }}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            sx={{ width: '100%' }}
          >
            <StyledButton onClick={onClose}>
              <Typography textTransform="none">Close</Typography>
            </StyledButton>
            <StyledButton onClick={handleSave}>
              <Typography textTransform="none">Save</Typography>{' '}
              <ArrowForwardIcon />
            </StyledButton>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EditModal;
