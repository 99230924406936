import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import { Box, Avatar, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Sidebar = () => {
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className="drawer"
      PaperProps={{
        sx: {
          backgroundColor: '#21847D',
          color: '#FFFFFF',
          maxWidth: '320px',
        },
      }}
    >
      <Box
        marginTop={3}
        marginBottom={2}
        sx={{
          display: 'flex',
          justifyContent: 'left',
          aligItems: 'center',
        }}
      >
        <img
          src="/assets/logoLeaf.png"
          alt="logo"
          style={{
            maxHeight: '40px',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '8px',
            paddingBottom: '8px',
          }}
        />
        <Box className="logo" sx={{ paddingTop: '8px', paddingBottom: '8px' }}>
          <Typography fontSize="0.8rem">
            HealthBoost <br />
            Assistent
          </Typography>
        </Box>
      </Box>
      <List>
        {[
          {
            text: 'Overview',
            icon: (
              <FileOpenOutlinedIcon
                sx={{ color: '#FFFFFF', '&:hover': { color: '#21847d' } }}
              />
            ),
          },
          {
            text: 'Recepies',
            icon: (
              <RamenDiningIcon
                sx={{ color: '#FFFFFF', '&:hover': { color: '#21847d' } }}
              />
            ),
          },
          {
            text: 'Plan For Your Menu',
            icon: (
              <MenuBookIcon
                sx={{ color: '#FFFFFF', '&:hover': { color: '#21847d' } }}
              />
            ),
          },
          {
            text: 'Members',
            icon: (
              <PeopleAltIcon
                sx={{ color: '#FFFFFF', '&:hover': { color: '#21847d' } }}
              />
            ),
          },
          {
            text: 'Add Member',
            icon: (
              <AddReactionOutlinedIcon
                sx={{ color: '#FFFFFF', '&:hover': { color: '#21847d' } }}
              />
            ),
          },
        ].map((item, index) => (
          <ListItemButton
            key={item.text}
            sx={{
              '&:hover': {
                backgroundColor: '#FFFFFF',
                color: '#21847D',
              },
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 'auto',
          padding: 2,
        }}
      >
        <Box className="profile" sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            sx={{
              color: '#FFFFFF',
              bgcolor: '#21847D',
              border: '1px solid white',
              marginRight: '5px',
            }}
          >
            JD
          </Avatar>
          Jenny Doe
        </Box>
        <ArrowForwardIcon />
      </Box>
    </Drawer>
  );
};

export default Sidebar;
