import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#17527d',
      fontWeight: 600,
    },
    success: {
      main: '#21847D',
    },

    warning: {
      main: '#EFEFEF',
    },

    background: {
      default: '#EFEFEF',
    },
  },
  typography: {
    main: {
      color: '#17527D',
      fontSize: '1rem',
      textAlign: 'center',
      fontFamily: 'Poppins, sans-serif',
    },
    inter: {
      fontFamily: 'Inter, sans-serif',
      color: '#17527D',
      textAlign: 'center',
      fontSize: '1rem',
    },
    h1: {
      fontFamily: 'Poppins, sans-serif',
    },
    h2: {
      fontFamily: 'Poppins, sans-serif',
    },
    h3: {
      fontFamily: 'Poppins, sans-serif',
    },
    h4: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 600,
    },
    h5: {
      fontFamily: 'Poppins, sans-serif',
    },
    h6: {
      fontFamily: 'Poppins, sans-serif',
    },
    subtitle1: {
      fontFamily: 'Poppins, sans-serif',
    },
    subtitle2: {
      fontFamily: 'Poppins, sans-serif',
    },
    body1: {
      fontFamily: 'Poppins, sans-serif',
    },
    body2: {
      fontFamily: 'Poppins, sans-serif',
    },
    button: {
      fontFamily: 'Poppins, sans-serif',
    },
    caption: {
      fontFamily: 'Poppins, sans-serif',
    },
    overline: {
      fontFamily: 'Poppins, sans-serif',
    },
  },
});

export default theme;
