import React, { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import './MedicalCondition.css';

const MedicalCondition = ({ onChange }) => {
  const [conditions, setConditions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddTag = () => {
    if (inputValue) {
      setConditions([...conditions, inputValue]);
      setInputValue('');
      onChange([...conditions, inputValue]);
    }
  };

  const handleRemoveTag = (index) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    setConditions(newConditions);
    onChange(newConditions);
  };

  return (
    <div style={{ width: '100%', paddingTop: '2rem' }}>
      <Box sx={{ width: '100%' }}>
        <Typography variant="main" color="primary">
          Type Your Medical Condition
        </Typography>
      </Box>
      <div style={{ paddingTop: '0.4rem' }}>
        <TextField
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          InputProps={{
            style: {
              height: '2.875rem',
              borderRadius: '0.30rem',
              border: '#D9D9D9',
            },
          }}
          style={{
            width: 'calc(81.5% - 4px)',
            marginRight: '8px',
            borderRadius: '0.30rem',
          }}
          className="inputMedicalCondition"
        />
        <Button
          varient="outlined"
          sx={{
            border: '1px solid #D9D9D9',
            textTransform: 'none',
            borderRadius: '0.30rem',
            width: 'calc(18.5% - 4px)',
            height: '2.875rem',
          }}
          onClick={handleAddTag}
        >
          <Typography color="primary" variant="main">
            Add
          </Typography>
        </Button>
      </div>
      <div
        className="conditions-container"
        style={{ alignContent: 'center', justifyItems: 'center' }}
      >
        {conditions.map((condition, index) => (
          <div
            key={index}
            className="condition-item"
            style={{
              display: 'inline-block',
              border: '1px solid #D9D9D9',
              borderRadius: '1rem',
              marginRight: '0.5rem',
              marginTop: '0.625rem',
              padding: '0.3rem 0.5rem',
              fontSize: '0.8rem',
            }}
          >
            <span style={{ color: '#17527D' }}>{condition}</span>
            <button
              onClick={() => handleRemoveTag(index)}
              style={{
                border: 'none',
                color: '#17527D',
                backgroundColor: 'white',
                fontSize: '1rem',
                cursor: 'pointer',
              }}
            >
              x
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MedicalCondition;
