import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)((props) => ({
  padding: '0.625rem 1.25rem',
  marginTop: '0.625rem',
  backgroundColor: '#17527D',
  color: '#FFFFFF',
  border: 'none',
  textTransform: 'capitalize',
  borderRadius: '0.30rem',
  height: '3.1875rem',
  '& a': {
    color: 'inherit',
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#F5F5F5',
    color: '#17527D',
  },
}));

const StyledButtonGrey = styled(Button)((props) => ({
  padding: '0.625rem 1.25rem',
  marginTop: '0.625rem',
  backgroundColor: '#F5F5F5',
  color: '#17527D',
  border: 'none',
  textTransform: 'capitalize',
  borderRadius: '0.30rem',
  height: '3.1875rem',
  '& a': {
    color: 'inherit',
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#F5F5F5',
    color: '#17527D',
  },
}));

const StyledButtonWhite = styled(Button)((props) => ({
  padding: '0.625rem 1.25rem',
  marginTop: '0.625rem',
  backgroundColor: '#FFFFFF',
  color: '#17527D',
  border: 'none',
  textTransform: 'capitalize',
  borderRadius: '0.30rem',
  height: '3.1875rem',
  '& a': {
    color: 'inherit',
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#F5F5F5',
    color: '#17527D',
  },
}));

const getTypographyStyles = (props) => {
  if (props.variant === 'heading1') {
    return {
      variant: 'h1',
      color: '#17527D',
      fontSize: '2rem',
      marginBottom: '0.625rem',
    };
  } else if (props.variant === 'heading2') {
    return {
      variant: 'h2',
      color: '#17527D',
      fontSize: '1rem',
      textAlign: 'center',
      marginBottom: '0.625rem',
    };
  }
  return {};
};

const StyledTypography = styled(Typography)(getTypographyStyles);

const SignIn = () => {
  const navigate = useNavigate();

  const handleRegisterButtonClick = () => {
    navigate('/register');
  };

  const handleGuestButtonClick = () => {
    navigate('/guest');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Grid container>
          <CssBaseline />

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 3,
                marginTop: '1.875rem',
                marginBottom: '2.75rem',
              }}
            >
              <StyledTypography component="h1" variant="heading1">
                Log in into your account
              </StyledTypography>
              <StyledTypography variant="heading2">
                Place where we embark on a journey to better health together
              </StyledTypography>

              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1, paddingTop: '1.875rem' }}
              >
                <label className="label">E-mail</label>
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  id="email"
                  placeholder="john.doe@email.com"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  sx={{ marginBottom: 2 }}
                  className="input"
                />
                <label className="label">Password</label>
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  name="password"
                  placeholder="*********"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  className="input"
                />
                <div style={{ marginTop: '0.8rem' }}>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => console.log('Forgot password?')}
                    sx={{ fontSize: '1.1rem' }}
                  >
                    Forgot Password?
                  </Link>
                </div>
                <StyledButton
                  type="submit"
                  fullWidth
                  sx={{ mt: 3, mb: 2, marginTop: '2.5rem' }}
                >
                  <Link to="/account"> Log In &rarr;</Link>
                </StyledButton>
                <hr
                  style={{
                    width: '100%',
                    marginBottom: '1.8rem',
                    marginTop: '1.8rem',
                    border: '1px solid',
                    color: '#D8D8D8',
                  }}
                />
                <StyledButtonGrey onClick={handleRegisterButtonClick} fullWidth>
                  <Link to="/register">Create new Account</Link>
                </StyledButtonGrey>
                <StyledButtonWhite onClick={handleGuestButtonClick} fullWidth>
                  <Link to="/guest">Continue as a Guest &rarr;</Link>
                </StyledButtonWhite>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <Box
              style={{
                backgroundImage: 'url(assets/tree.png)',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                  t.palette.mode === 'light'
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: 'contain',
                width: '100%',
                height: '39vh',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SignIn;
