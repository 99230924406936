import React, { useState } from 'react';
import { Typography, Button } from '@mui/material';
import TextField from "@mui/material/TextField";


const Allergies = ({ onChange }) => {
  const [allergies, setAllergies] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddTag = () => {
    if (inputValue) {
      setAllergies([...allergies, inputValue]);
      setInputValue('');
      onChange([...allergies, inputValue]);
    }
  };

  const handleRemoveTag = (index) => {
    const newAllergies = [...allergies];
    newAllergies.splice(index, 1);
    setAllergies(newAllergies);
    onChange(newAllergies);
  };

  return (
    <div style={{ width: '100%' }}>
      <Typography variant="main" color="primary">
        Type your allergies
      </Typography>
      <div style={{ paddingTop: "0.4rem" }}>
        <TextField
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          InputProps={{
            style: {
              height: "2.875rem",
              borderRadius: "0.30rem",
              border: "#D9D9D9",
            },
          }}
          style={{
            width: 'calc(81.5% - 4px)',
            height: "2.875rem",
            marginRight: '8px',
          }}
        />
        <Button
          varient="outlined"
          sx={{
            border: "1px solid #D9D9D9",
            textTransform: "none",
            borderRadius: "0.30rem",
            width: "calc(18.5% - 4px)",
            height: "2.875rem",
          }}
          onClick={handleAddTag}
        >
          <Typography variant="main" color="primary">
            Add
          </Typography>
        </Button>
      </div>
      <div className="allergies-container">
        {allergies.map((allergy, index) => (
          <div
            key={index}
            className="allergies-item"
            style={{
              display: "inline-block",
              border: "1px solid #D9D9D9",
              borderRadius: "1rem",
              marginRight: "0.5rem",
              marginTop: "0.625rem",
              padding: "0.3rem 0.5rem",
              fontSize: "0.8rem",
            }}
          >
            <span style={{ color: '#17527D' }}>{allergy}</span>
            <button
              onClick={() => handleRemoveTag(index)}
              style={{
                border: "none",
                color: "#17527D",
                backgroundColor: "white",
                fontSize: "1rem",
                cursor: "pointer",
              }}
            >
              x
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Allergies;
