import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SignIn from './Pages/HomePage';
import GuestPage from './Pages/GuestPage';
import EditPage from './Pages/EditPage';
import Register from './Pages/Register';
import GuestResponse from './Pages/GuestResponse';
import AccountResponse from './Pages/AccountResponse';
import AccountPage from './Pages/AccountPage';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" exact element={<SignIn />} />
      <Route path="/guest" exact element={<GuestPage />} />
      <Route path="/edit" exact element={<EditPage />} />
      <Route path="/account" exact element={<AccountPage />} />
      <Route path="/register" exact element={<Register />} />
      <Route path="/guestresponse" exact element={<GuestResponse />} />
      <Route path="/accountresponse" exact element={<AccountResponse />} />
    </Routes>
  </BrowserRouter>
);

export default App;
