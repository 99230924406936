import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";

const ChooseYourAge = ({ onChange }) => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
    onChange(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Box sx={{ width: "100%", paddingBottom: '0.4rem' }}>
          <Typography variant="main" color="primary">
            Choose Your Age Range
          </Typography>
        </Box>
        <InputLabel
          id="demo-simple-select-label"
          color="primary"
          disableAnimation
        ></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          onChange={handleChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 100,
                overflowY: "auto",
              },
            },
          }}
        >
          <MenuItem value="Placeholder" color="primary" disabled>
            <Typography variant="span" color="primary">
              Choose Your Age Range
            </Typography>
          </MenuItem>
          <MenuItem value="18-30" color="primary">
            <Typography variant="span" color="primary">
              18-30
            </Typography>
          </MenuItem>
          <MenuItem value="31-45">
            <Typography variant="span" color="primary">
              31-45
            </Typography>
          </MenuItem>
          <MenuItem value="46-60">
            <Typography variant="span" color="primary">
              46-60
            </Typography>
          </MenuItem>
          <MenuItem value="60-75">
            <Typography variant="span" color="primary">
              60-75
            </Typography>
          </MenuItem>
          <MenuItem value="75-90">
            <Typography variant="span" color="primary">
              75-90
            </Typography>
          </MenuItem>
          <MenuItem value="90+">
            <Typography variant="span" color="primary">
              90+
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default ChooseYourAge;
