import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CheckIcon from '@mui/icons-material/Check';
import { Typography, Box } from '@mui/material';
import './FoodPreferences.css';

const FoodPreferences = ({ onChange }) => {
  const [selectedButtons, setSelectedButtons] = useState([]);

  useEffect(() => {
    onChange(selectedButtons);
  }, [selectedButtons, onChange]);

  const handleButtonClick = (value) => {
    if (selectedButtons.includes(value)) {
      setSelectedButtons(selectedButtons.filter((button) => button !== value));
    } else {
      setSelectedButtons([...selectedButtons, value]);
    }
  };

  const isButtonSelected = (value) => {
    return selectedButtons.includes(value);
  };

  return (
    <Box>
      <Box>
        <Typography variant="main" color="primary">
          Food Preferences
        </Typography>
        <Box style={{ maxHeight: 106, overflowX: 'auto' }}>
          <ButtonGroup
            color="success"
            aria-label="outlined primary button group"
            sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}
          >
            <Button
              id="none"
              onClick={() => handleButtonClick('None')}
              variant={
                isButtonSelected('None') ? 'contained' : 'outlined primary '
              }
              style={{ color: isButtonSelected('None') ? 'white' : '#17527D' }}
            >
              None {isButtonSelected('None') && <CheckIcon />}
            </Button>
            <Button
              id="vegetarian"
              onClick={() => handleButtonClick('Vegetarian')}
              variant={
                isButtonSelected('Vegetarian')
                  ? 'contained'
                  : 'outlined primary'
              }
              style={{
                color: isButtonSelected('Vegetarian') ? 'white' : '#17527D',
              }}
            >
              Vegetarian {isButtonSelected('Vegetarian') && <CheckIcon />}
            </Button>
            <Button
              id="vegan"
              onClick={() => handleButtonClick('Vegan')}
              variant={
                isButtonSelected('Vegan') ? 'contained' : 'outlined primary'
              }
              style={{ color: isButtonSelected('Vegan') ? 'white' : '#17527D' }}
            >
              Vegan {isButtonSelected('Vegan') && <CheckIcon />}
            </Button>
            <Button
              id="pescatarian"
              onClick={() => handleButtonClick('Pescatarian')}
              variant={
                isButtonSelected('Pescatarian')
                  ? 'contained'
                  : 'outlined primary'
              }
              style={{
                color: isButtonSelected('Pescatarian') ? 'white' : '#17527D',
              }}
            >
              Pescatarian {isButtonSelected('Pescatarian') && <CheckIcon />}
            </Button>
            <Button
              id="semi"
              onClick={() => handleButtonClick('Semi-Vegetarian')}
              variant={
                isButtonSelected('Semi-Vegetarian')
                  ? 'contained'
                  : 'outlined primary'
              }
              style={{
                color: isButtonSelected('Semi-Vegetarian')
                  ? 'white'
                  : '#17527D',
              }}
            >
              Semi-Vegetarian{' '}
              {isButtonSelected('Semi-Vegetarian') && <CheckIcon />}
            </Button>
            <Button
              id="paleo"
              onClick={() => handleButtonClick('Paleo')}
              variant={
                isButtonSelected('Paleo') ? 'contained' : 'outlined primary'
              }
              style={{ color: isButtonSelected('Paleo') ? 'white' : '#17527D' }}
            >
              Paleo {isButtonSelected('Paleo') && <CheckIcon />}
            </Button>
            <Button
              id="keto"
              onClick={() => handleButtonClick('Keto')}
              variant={
                isButtonSelected('Keto') ? 'contained' : 'outlined primary'
              }
              style={{ color: isButtonSelected('Keto') ? 'white' : '#17527D' }}
            >
              Keto {isButtonSelected('Keto') && <CheckIcon />}
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
      <Box>
        <span style={{ color: 'grey', height: 'auto' }}>
          {selectedButtons.join(', ')}
        </span>
      </Box>
    </Box>
  );
};

export default FoodPreferences;
