import React from 'react';
import { Container, Grid, Box } from '@mui/material';
import Sidebar from '../Components/Sidebar';

const AccountResponse = () => {
  return (
    <Container component="main" maxWidth="lg">
      <Grid container>
        <Grid item xs={3} sm={3} md={3} elevation={6} square="true">
          <Sidebar />
        </Grid>
        <Grid
          item
          container
          dirrection="row"
          xs={9}
          sm={9}
          md={9}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            marginLeft: { sm: 0, md: 3 },
            marginTop: 8,
          }}
        >
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Box sx={{ border: '1px solid #000000' }} margin={2}>
                Details with Edit button (modal)
              </Box>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              id="main-content"
              dirrection="row"
              spacing={2}
              padding={2}
            >
              <Grid item xs={6}>
                <Box sx={{ border: '1px solid #000000' }}>
                  Vitamisn and
                  mineralss........................................Where does it
                  come from? Contrary to popular belief, Lorem Ipsum is not
                  simply random text. It has roots in a piece of classical Latin
                  literature from 45 BC, making it over 2000 years old. Richard
                  McClintock, a Latin professor at Hampden-Sydney College in
                  Virginia, looked up one of the more obscure Latin words,
                  consectetur, from a Lorem Ipsum passage, and going through the
                  cites of the word in classical literature, discovered the
                  undoubtable source. Lorem Ipsum comes from sections 1.10.32
                  and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes
                  of Good and Evil) by Cicero, writtenm
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ border: '1px solid #000000' }}>
                  Vitamisn and
                  mineralss........................................Where does it
                  come from? Contrary to popular belief, Lorem Ipsum is not
                  simply random text. It has roots in a piece of classical Latin
                  literature from 45 BC, making it over 2000 years old. Richard
                  McClintock, a Latin professor at Hampden-Sydney College in
                  Virginia, looked up one of the more obscure Latin words,
                  consectetur, from a Lorem Ipsum passage, and going through the
                  cites of the word in classical literature, discovered the
                  undoubtable source. Lorem Ipsum comes from sections 1.10.32
                  and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes
                  of Good and Evil) by Cicero, writtenm
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} marginX={2}>
              <Box sx={{ border: '1px solid black' }}>
                Vitamins and minerals full with card
                ...............................Where does it come from? Contrary
                to popular belief, Lorem Ipsum is not simply random text. It has
                roots in a piece of classical Latin literature from 45 BC,
                making it over 2000 years old. Richard McClintock, a Latin
                professor at Hampden-Sydney College in Virginia, looked up one
                of the more obscure Latin words, consectetur, from a Lorem Ipsum
                passage,
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} margin={2}>
              <Box sx={{ border: '1px solid black' }}>
                Food Recommendation............................................
                Where does it come from? Contrary to popular belief, Lorem Ipsum
                is not simply random text. It has roots in a piece of classical
                Latin literature from 45 BC, making it over 2000 years old.
                Richard McClintock, a Latin professor at Hampden-Sydney College
                in Virginia, looked up one of the more obscure Latin words,
                consectetur, from a Lorem Ipsum passage,
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} marginX={2}>
              <Box sx={{ border: '1px solid black' }}>
                It's important to note that I'm not a doctor, and you should
                consult with a healthcare professional for personalized advice.
                However, I can provide some general information about vitamins
                and minerals that may be beneficial for individuals with
                diabetes, especially if you're following a vegetarian diet.
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccountResponse;
