import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  CardHeader,
  Avatar,
} from '@mui/material';

const avatarBgColors = ['#21847D', '#7C8421', '#6F3806', '#842180'];

const getRandomColor = () => {
  return avatarBgColors[Math.floor(Math.random() * avatarBgColors.length)];
};

const Cards = ({ title, description, link }) => {
  return (
    <Box width="auto">
      <Card sx={{ minHeight: '195px', borderRadius: '0.5rem' }}>
        <CardHeader
          titleTypographyProps={{
            sx: {
              color: '#001626',
              fontSize: '1rem',
              fontWeight: '600',
            },
          }}
          avatar={
            <Avatar
              sx={{
                bgcolor: getRandomColor(),
                width: 'auto',
                height: '1.5rem',
                borderRadius: '1.5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              aria-label="recipe"
            >
              <Typography padding={1}>{title}</Typography>
            </Avatar>
          }
          title={title}
        />

        <CardContent>
            {description}
        </CardContent>
        <CardActions>
          <Button size="small">
            <Typography variant="subtitle2" textTransform="none">
              <a href={link} target="_blank" rel="noopener noreferrer">
                Show more info
              </a>
            </Typography>
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default Cards;
