import React, { useState, useEffect } from 'react';
import Card from '../Components/Card';
import {
  Box,
  Typography,
  Container,
  CircularProgress,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditModal from '../Components/EditModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const GuestResponse = () => {
  const [openModal, setOpenModal] = useState(false);
  const [allNutrients, setAllNutrients] = useState([]);
  const [displayedNutrients, setDisplayedNutrients] = useState([]);
  const [showAdditionalNutrients, setShowAdditionalNutrients] = useState(false);
  const [allFoodRec, setallFoodRec] = useState([]);
  const [displayedFoodRec, setdisplayedFoodRec] = useState([]);
  const [showAdditionalFoodRec, setshowAdditionalFoodRec] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [introText, setIntroText] = useState('');
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [userData, setUserData] = useState({
    medicalCondition: [],
    allergies: [],
    age: '',
    foodPreferences: [],
    gender: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedData = JSON.parse(localStorage.getItem('apiData'));
        if (storedData) {
          setApiData(storedData);
          return;
        }

        const userData = JSON.parse(localStorage.getItem('userData')) || {};
        const queryString = Object.keys(userData)
          .map((key) => {
            if (Array.isArray(userData[key])) {
              return userData[key]
                .map(
                  (value) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                )
                .join('&');
            } else {
              return `${encodeURIComponent(key)}=${encodeURIComponent(
                userData[key]
              )}`;
            }
          })
          .join('&');
        console.log('API Call with Query String:', queryString);

        const apiUrl1 = `https://hba.bojanstojanovic.com/cgi-bin/prompt-lite-raw.py?${queryString}`;
        const apiUrl2 = `https://hba.bojanstojanovic.com/cgi-bin/intro.py?${queryString}`;
        console.log('API URLs:', apiUrl1, apiUrl2);

        const [response1, response2] = await Promise.all([
          fetch(apiUrl1, { mode: 'cors' }),
          fetch(apiUrl2, { mode: 'cors' }),
        ]);

        if (response1.ok) {
          const responseBody1 = await response1.text();
          console.log('Raw Response Body 1:', responseBody1);

          const jsonStartIndex = responseBody1.indexOf('{');
          const jsonEndIndex = responseBody1.lastIndexOf('}');
          const jsonData = responseBody1.substring(
            jsonStartIndex,
            jsonEndIndex + 1
          );

          const responseData1 = JSON.parse(jsonData);
          setApiData(responseData1);
          localStorage.setItem('apiData', JSON.stringify(responseData1));
          console.log('API Response Data 1:', responseData1);
        } else {
          console.error('API Call Error 1:', response1.statusText);
        }

        if (response2.ok) {
          const responseBody2 = await response2.text();
          console.log('Raw Response Body 2:', responseBody2);
          setIntroText(responseBody2);
          localStorage.setItem('intro', responseBody2);
        } else {
          console.error('API Call Error 2:', response2.statusText);
        }
      } catch (error) {
        console.error('API Call Error:', error.message);
      }
    };

    fetchData();
  }, [introText]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData')) || {};
    setUserData({
      diagnoses: [],
      allergies: [],
      ageRange: '',
      preferences: [],
      gender: '',
      ...storedData,
    });
  }, [openModal]);

  useEffect(() => {
    if (apiData) {
      let allNutrients = [];
      Object.values(apiData).forEach((data) => {
        if (data?.Diagnoses) {
          Object.values(data.Diagnoses).forEach((diagnosis) => {
            allNutrients.push(...Object.entries(diagnosis.Nutrients));
          });
        }
      });
      setAllNutrients(allNutrients);
      setDisplayedNutrients(allNutrients.slice(0, 4));
    }
  }, [apiData]);

  useEffect(() => {
    if (apiData) {
      const foodRecData = apiData['2'];
      if (foodRecData) {
        setallFoodRec(foodRecData);
        const vitaminsEntries = Object.entries(foodRecData);
        setdisplayedFoodRec(vitaminsEntries.slice(0, 4));
      }
    }
  }, [apiData]);

  useEffect(() => {
    return () => {
      localStorage.clear();
    };
  }, []);

  useEffect(() => {
    const storedIntroText = localStorage.getItem('intro');
    console.log('Stored Intro Text:', storedIntroText);
    if (storedIntroText) {
      setIntroText(storedIntroText);
    }
  }, [setIntroText]);

  const handleLoadMoreFoodRecClick = () => {
    setshowAdditionalFoodRec((prev) => !prev);
    if (apiData && apiData['2']) {
      const vitaminsEntries = Object.entries(apiData['2']);
      const newdisplayedFoodRec = showAdditionalFoodRec
        ? vitaminsEntries.slice(0, 4)
        : vitaminsEntries;
      setdisplayedFoodRec(newdisplayedFoodRec);
    }
  };

  const handleLoadMoreNutrientsClick = () => {
    setShowAdditionalNutrients((prev) => !prev);
    if (apiData) {
      let newDisplayedNutrients = showAdditionalNutrients
        ? allNutrients.slice(0, 4)
        : allNutrients;
      setDisplayedNutrients(newDisplayedNutrients);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSaveModal = (newData) => {
    localStorage.setItem('userData', JSON.stringify(newData));
    setOpenModal(false);
  };

  return (
    <Container
      component="main"
      maxWidth="lg"
      style={{ backgroundColor: '#EFEFEF' }}
    >
      {!apiData ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
          <Typography color="#21847D" sx={{ paddingTop: '1rem' }}>
            Waiting for response, this may take some time...
          </Typography>
        </Box>
      ) : (
        <>
          <Box marginBottom={2} paddingTop={1}>
            <Button>
              <ArrowBackIcon fontSize="0.8rem" />
              <Typography textTransform="none">
                <Link style={{ textDecoration: 'none' }} to="/guest">
                  Back
                </Link>
              </Typography>
            </Button>
          </Box>

          <Grid container>
            <Grid item xs={12} sm={3} md={3} elevation={6} square="true">
              <>
                <Box
                  sx={{ backgroundColor: '#FFFFFF', borderRadius: '6px' }}
                  m={3}
                  p={2}
                >
                  <Typography variant="h6" color="#6D94B0" fontSize="0.8rem">
                    Medical Condition:
                  </Typography>
                  <Typography marginBottom={2} fontSize="1rem">
                    {userData.diagnoses.join(', ')}
                  </Typography>
                  <Typography variant="h6" color="#6D94B0" fontSize="0.8rem">
                    Allergies
                  </Typography>
                  <Typography marginBottom={2} fontSize="1rem">
                    {userData.allergies.join(', ')}
                  </Typography>
                  <Typography variant="h6" color="#6D94B0" fontSize="0.8rem">
                    Age range
                  </Typography>
                  <Typography marginBottom={2} fontSize="1rem">
                    {userData.age}
                  </Typography>
                  <Typography variant="h6" color="#6D94B0" fontSize="0.8rem">
                    Food Preferences
                  </Typography>
                  <Typography marginBottom={2} fontSize="1rem">
                    {userData.preferences.join(', ')}
                  </Typography>
                  <Typography variant="h6" color="#6D94B0" fontSize="0.8rem">
                    Gender
                  </Typography>
                  <Typography marginBottom={2} fontSize="1rem">
                    {userData.gender}
                  </Typography>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={handleOpenModal}
                  >
                    <Typography textTransform="none">
                      <Link
                        style={{
                          textDecoration: 'none',
                          color: '#17527D',
                          fontSize: '1rem',
                        }}
                      >
                        Edit
                      </Link>
                    </Typography>
                  </Button>
                </Box>
                <Box
                  sx={{ backgroundColor: '#FFFFFF', borderRadius: '6px' }}
                  m={3}
                  p={2}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      color: '#17527D',
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                    marginBottom={2}
                  >
                    <InfoOutlinedIcon />{' '}
                    <Typography fontSize={16} marginLeft={1}>
                      Read More
                    </Typography>
                  </Box>

                  <Typography
                    sx={{ color: '#6D94B0' }}
                    marginBottom={2}
                    fontSize="0.8rem"
                  >
                    Please register for more food suggestions, recipes and
                    weekly manu advice
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" color="success" fullWidth>
                      <Typography textTransform="none">
                        <Link
                          style={{
                            textDecoration: 'none',
                            color: 'white',
                          }}
                          to="/register"
                        >
                          Create Account
                        </Link>
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </>
            </Grid>

            <Grid
              item
              container
              direction="row"
              xs={12}
              sm={9}
              md={9}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Grid item sx={{ paddingTop: '1.5rem', margin: '0.4rem' }}>
                <Accordion
                  expanded={accordionExpanded}
                  onChange={(event, isExpanded) =>
                    setAccordionExpanded(isExpanded)
                  }
                >
                  <AccordionSummary>
                    <Typography variant="h5" color="primary">
                      Show Dietary Information
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>{introText}</p>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Typography
                variant="h4"
                color="primary"
                sx={{ marginRight: 'auto' }}
                marginTop={2}
                marginLeft={1}
                marginBottom={2}
              >
                Vitamins and Minerals
              </Typography>
              <Grid
                container
                direction="row"
                sx={{ rowGap: '20px', display: 'flex' }}
              >
                {displayedNutrients.map(([nutrientName, nutrient], index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={`${nutrientName}-${index}`}
                    className={'firstAppear'}
                    paddingX={1}
                  >
                    <Card
                      title={nutrientName}
                      description={nutrient?.Function}
                      link={nutrient?.Diagnosis_Nutrient_Related_URL}
                    />
                  </Grid>
                ))}
                {apiData && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleLoadMoreNutrientsClick}
                      sx={{
                        backgroundColor: ' rgba(33, 132, 125, 0.15)',
                        textTransform: 'none',
                        width: '25%',
                        border: 'none',
                      }}
                    >
                      {showAdditionalNutrients ? 'Show Less' : 'Show More'}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Typography
                variant="h4"
                color="primary"
                sx={{ marginRight: 'auto' }}
                marginTop={2}
                marginLeft={1}
              >
                Food Recommendation
              </Typography>
              <Grid item container row="true" sx={{ rowGap: '30px' }}>
                {displayedFoodRec.map(([nutrientName, nutrient], index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    key={`${nutrientName}-${index}`}
                    className="firstAppear"
                    sx={{ width: '100%' }}
                    paddingX={1}
                  >
                    <Card
                      sx={{
                        fontWeight: '500',
                        fontFamily: 'Inter',
                        fontSize: '1rem',
                        color: '#2f2f2f',
                      }}
                      title={nutrientName}
                      description={
                        <ul>
                          {Object.entries(nutrient.Source).map(
                            ([sourceName, sourceData]) => (
                              <li key={sourceName}>
                                <strong>{sourceName}</strong> -{' '}
                                {Object.entries(sourceData)
                                  .map(([key, value]) => `${key}: ${value}`)
                                  .join(', ')}
                              </li>
                            )
                          )}
                        </ul>
                      }
                    />
                  </Grid>
                ))}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {apiData && apiData['2'] && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleLoadMoreFoodRecClick}
                      sx={{
                        backgroundColor: ' rgba(33, 132, 125, 0.15)',
                        textTransform: 'none',
                        width: '25%',
                      }}
                    >
                      {showAdditionalFoodRec ? 'Show Less' : 'Show More'}
                    </Button>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ border: '1px solid grey', width: '100%' }}
                  id="boxic"
                  backgroundColor="#FFFFFF"
                  borderRadius="8px"
                >
                  <Typography
                    variant="subtitle2"
                    fullwidth="true"
                    padding={2}
                    color="#001626"
                    fontSize="0.75rem"
                    fontFamily="Inter"
                  >
                    It's important to note that I'm not a doctor, and you should
                    consult with healthcare professional for personalized
                    advice. However, I can provide some general information
                    about vitamins and minerals that may be beneficial for
                    individuals with diabetes, especially if you're following a
                    vegetarian diet.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <EditModal
            open={openModal}
            onClose={handleCloseModal}
            onSave={handleSaveModal}
          />
        </>
      )}
    </Container>
  );
};

export default GuestResponse;
