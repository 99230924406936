import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Stack,
  Container,
  Paper,
  Grid,
  CssBaseline,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import ChooseYourAge from '../Components/ChooseYourAge';
import FoodPreferences from '../Components/FoodPreferences';
import MedicalCondition from '../Components/MedicalCondition';
import Allergies from '../Components/Allergies';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledButton = styled(Button)((props) => ({
  padding: '0.625rem 1.25rem',
  marginTop: '0.625rem',
  backgroundColor: '#17527D',
  color: '#FFFFFF',
  border: 'none',
  textTransform: 'capitalize',
  borderRadius: '0.30rem',
  height: '3.1875rem',

  '& a': {
    color: 'inherit',
    textDecoration: 'none',
  },

  '&:hover': {
    backgroundColor: '#F5F5F5',
    color: '#17527D',
  },
}));

const EditPage = () => {
  const [value, setValue] = useState('');
  console.log({ value });
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Grid container>
          <CssBaseline />

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h4"
                color="primary"
                align="center"
                marginBottom={1}
              >
                Edit Your Data
              </Typography>
              <Typography
                variant="subtitle2"
                color="primary"
                align="center"
                sx={{ fontWeight: 600 }}
                marginBottom={3}
              >
                Personalized guide to optimal well-being, exploring the vitamins
                and minerals in food tailored to your unique needs
              </Typography>
              <Box
                className="typeYourMedicalCondition"
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                marginBottom={4}
              >
                <MedicalCondition />
              </Box>
              <Box
                className="typeYourAlergies"
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                marginBottom={4}
              >
                <Allergies />
              </Box>
              <Box
                className="chooseYourAge"
                sx={{ width: '100%' }}
                marginBottom={4}
              >
                <ChooseYourAge />
              </Box>
              <Box
                className="foodPreferences"
                sx={{ width: '100%' }}
                marginBottom={4}
              >
                <FoodPreferences />
              </Box>
              <Box
                className="gender"
                sx={{ width: '100%', marginLeft: 'auto' }}
                marginBottom={4}
              >
                <FormControl primary>
                  <FormLabel id="select-gender-group-label">
                    <Typography variant="main" color="primary">
                      Select your gender
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    name="select-gender"
                    aria-labelledby="select-gender-group-label"
                    value={value}
                    onChange={handleChange}
                    row
                    color="primary"
                  >
                    <FormControlLabel
                      control={<Radio size="small" />}
                      label="Male"
                      value="Male"
                      color="primary"
                      style={{ color: '#17527D' }}
                    />
                    <FormControlLabel
                      control={<Radio size="small" />}
                      label="Female"
                      value="Female"
                      color="primary"
                      style={{ color: '#17527D' }}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Stack spacing={2} direction="column" sx={{ width: '100%' }}>
                <StyledButton>
                  <Typography textTransform="none">
                    <Link to="/accountresponse">Submit</Link>
                  </Typography>{' '}
                  <ArrowForwardIcon />
                </StyledButton>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              backgroundImage: 'url(https://source.unsplash.com/random)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light'
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </Grid>
      </Box>
    </Container>
  );
};

export default EditPage;
