import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  Stack,
  Container,
  Paper,
  Grid,
  CssBaseline,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import ChooseYourAge from '../Components/ChooseYourAge';
import FoodPreferences from '../Components/FoodPreferences';
import MedicalCondition from '../Components/MedicalCondition';
import Allergies from '../Components/Allergies';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledButton = styled(Button)((props) => ({
  padding: '0.625rem 1.25rem',
  marginTop: '0.625rem',
  backgroundColor: '#17527D',
  color: '#FFFFFF',
  border: 'none',
  textTransform: 'capitalize',
  borderRadius: '0.30rem',
  height: '3.1875rem',

  '& a': {
    color: 'inherit',
    textDecoration: 'none',
  },

  '&:hover': {
    backgroundColor: '#F5F5F5',
    color: '#17527D',
  },
}));

const StyledButtonWhite = styled(Button)((props) => ({
  padding: '0.625rem 1.25rem',
  marginTop: '0.625rem',
  backgroundColor: '#FFFFFF',
  color: '#17527D',
  border: '1px solid #17527D',
  textTransform: 'capitalize',
  borderRadius: '0.30rem',
  height: '3.1875rem',
  '& a': {
    color: 'inherit',
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#F5F5F5',
    color: '#17527D',
  },
}));

const GuestPage = () => {
  const navigate = useNavigate();
  const [medicalCondition, setMedicalCondition] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [age, setAge] = useState('');
  const [foodPreferences, setFoodPreferences] = useState([]);
  const [gender, setGender] = useState('');

  const handleMedicalConditionChange = (selectedConditions) => {
    setMedicalCondition(selectedConditions);
  };

  const handleAllergiesChange = (selectedAllergies) => {
    setAllergies(selectedAllergies);
  };

  const handleAgeChange = (selectedAge) => {
    setAge(selectedAge);
  };

  const handleFoodPreferencesChange = (selectedPreferences) => {
    setFoodPreferences(selectedPreferences);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleCreateAccount = () => {
    saveDataToLocalStorage();
    navigate('/register');
  };

  const saveDataToLocalStorage = () => {
    const userData = {
      diagnoses: medicalCondition,
      allergies,
      age,
      preferences: foodPreferences,
      gender,
    };
    localStorage.setItem('userData', JSON.stringify(userData));
  };

  const handleLoginAsGuest = () => {
    saveDataToLocalStorage();
    navigate('/guestresponse');
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Box marginBottom={1} paddingTop={1} marginLeft={-9}>
          <Button>
            <ArrowBackIcon fontSize="0.8rem" />
            <Typography textTransform="none">
              <Link style={{ textDecoration: 'none' }} to="/guest">
                Back
              </Link>
            </Typography>
          </Button>
        </Box>
        <Grid container>
          <CssBaseline />

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h4"
                color="primary"
                align="center"
                marginBottom={1}
              >
                Enter Your Data
              </Typography>
              <Typography
                variant="subtitle2"
                color="primary"
                align="center"
                sx={{ fontWeight: 600 }}
                marginBottom={3}
              >
                Personalized guide to optimal well-being, exploring the vitamins
                and minerals in food tailored to your unique needs
              </Typography>
              <Box
                className="typeYourMedicalCondition"
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                marginBottom={4}
              >
                <MedicalCondition onChange={handleMedicalConditionChange} />
              </Box>
              <Box
                className="typeYourAlergies"
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                marginBottom={4}
              >
                <Allergies onChange={handleAllergiesChange} />
              </Box>
              <Box
                className="chooseYourAge"
                sx={{ width: '100%' }}
                marginBottom={4}
              >
                <ChooseYourAge onChange={handleAgeChange} />
              </Box>
              <Box
                className="foodPreferences"
                sx={{ width: '100%' }}
                marginBottom={4}
              >
                <FoodPreferences onChange={handleFoodPreferencesChange} />
              </Box>
              <Box
                className="gender"
                sx={{ width: '100%', marginLeft: 'auto' }}
                marginBottom={4}
              >
                <FormControl>
                  <FormLabel id="select-gender-group-label">
                    <Typography variant="main" color="primary">
                      Select your gender
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    name="select-gender"
                    aria-labelledby="select-gender-group-label"
                    value={gender}
                    onChange={handleGenderChange}
                    row
                    color="primary"
                  >
                    <FormControlLabel
                      control={<Radio size="small" />}
                      label="Male"
                      value="Male"
                      color="primary"
                    />
                    <FormControlLabel
                      control={<Radio size="small" />}
                      label="Female"
                      value="Female"
                      color="primary"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Stack spacing={2} direction="column" sx={{ width: '100%' }}>
                <StyledButton onClick={handleLoginAsGuest}>
                  <Typography textTransform="none">
                    <Link to="/guestresponse">Submit </Link>
                  </Typography>{' '}
                  <ArrowForwardIcon />
                </StyledButton>
                <StyledButtonWhite
                  color="primary"
                  onClick={handleCreateAccount}
                  disabled={true}
                >
                  <Typography textTransform="none">
                    <Link to="/register">Create Account</Link>
                  </Typography>
                </StyledButtonWhite>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <div
              style={{
                backgroundImage: 'url(assets/tree.png)',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                  t.palette.mode === 'light'
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: 'contain',
                width: '100%',
                height: '39vh',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default GuestPage;
